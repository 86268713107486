import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* create({ payload: { companyId, values, sectionKey } }) {
  try {
    const { data } = yield call(dataProvider.createOne, `companies/${companyId}/references`, values);
    yield put({ type: currentCompany.reference.create.end, payload: data });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.reference.create.error });
  }
}

export default function* createReferenceSaga() {
  yield takeLatest(currentCompany.reference.create.start, create);
}
