import React, { useMemo, useState } from 'react';
import { Formik, FastField } from 'formik';
import { Button, Grid, Collapse } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';

import { filterCompanyListShema } from '../../../../utils/validate/shemas';
import FormInput from '../../../components/FormInput';
import FormNumberInput from '../../../components/FormNumberInput';
import MultiselectDropdown from '../../../components/MultiselectDropdown';
import MinMax from '../../../components/MinMax';
import Effect from '../../../components/Effect';
import ShowMoreLessFilters from '../../../components/ShowMoreLessFilters';
import { useUserPermissions } from '../../../../utils/effects/userPermissions';
import { companyRolesSelectionOptions } from '../../../../utils/constants/companyRoleTypes';
import { clearFilterValues, anyFilters, isEmptyFilterForm } from '../../../../utils/containersHelpers';
import { userTypes } from '../../../../utils/constants';
import useStyles from './styles';
import routes from '../../../routes/path';
import { statusesOptions } from '../../../../utils/constants';
import { favoriteOptions, overdueOptions } from '../../../../utils/constants/formConstats';
import usaStates from '../../../../utils/constants/usaStates';
import grades from '../../../../utils/constants/grades';
import FormAutocomplete from '../../../components/FormAutocomplete';
import ExportWorkingHoursModal from '../../ExportWorkingHoursModal';

const WatchEffect = ({ debounceFunc, submitForm, values, dirty }) => {
  if (dirty) return <Effect values={values} submitForm={submitForm} debounceFunc={debounceFunc} />;

  return '';
};
const transformCategories = groups =>
  groups.reduce(
    (acc, group) => [...acc, ...group.workCategories.map(category => ({ ...category, groupName: group.name }))],
    [],
  );

const CreateFilterForm = ({
  handleOnChange,
  allServices,
  allWorkCategories,
  values,
  isSafetyCouncil,
  handleOnExport,
  exporting,
  handleExportWorkingHours,
  exportingWorkingHours,
  canExportWorkingHours,
}) => {
  const classes = useStyles();
  const { type } = useUserPermissions();

  const { companyName, ...hiddenValues } = values;

  const checkOpenMore = anyFilters(hiddenValues);
  const clearValues = clearFilterValues(values);
  const isEmptyForm = isEmptyFilterForm(values);

  const [moreFilter, setMoreFilter] = useState(checkOpenMore);
  const [isExportWorkingHoursModalOpen, setIsExportWorkingHoursModalOpen] = useState(false);

  const isSafetyCouncilUserType = type === userTypes.SafetyCouncil;
  const transformedWorkCategories = useMemo(() => transformCategories(allWorkCategories), [allWorkCategories]);
  const transformStates = usaStates.reduce((acc, state) => [...acc, { id: state.value, name: state.label }], []);
  const transformGrades = grades.reduce((acc, grade) => [...acc, { id: grade.value, name: grade.label }], []);

  const debounceFunc = debounce(handleOnChange, 1000);

  const handleExportWorkingHoursModal = () => setIsExportWorkingHoursModalOpen(prev => !prev);

  return (
    <Formik
      initialValues={values}
      validationSchema={filterCompanyListShema}
      onSubmit={debounceFunc}
      validateOnChange={false}
    >
      {({ submitForm, setValues, values, dirty }) => (
        <Grid container justify="space-between">
          <WatchEffect values={values} submitForm={submitForm} debounceFunc={debounceFunc} dirty={dirty} />
          <Grid item xs={12} className={classes.gutterBottom}>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={10}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <FastField placeholder="Search Company" name="companyName" type="search" component={FormInput} />
                  </Grid>
                  <Grid item xs={2}>
                    <ShowMoreLessFilters
                      moreFilter={moreFilter}
                      setMoreFilter={setMoreFilter}
                      isEmptyForm={isEmptyForm}
                      clearFilterValues={() => setValues(clearValues)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={exporting ? <CircularProgress size={20} /> : <GetAppIcon />}
                          onClick={() => handleOnExport()}
                          disabled={exporting}
                        >
                          Export
                        </Button>
                      </Grid>
                      {canExportWorkingHours && (
                        <Grid item xs={9}>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={exportingWorkingHours ? <CircularProgress size={20} /> : <GetAppIcon />}
                            onClick={handleExportWorkingHoursModal}
                            disabled={exportingWorkingHours}
                          >
                            Export Working Hours
                          </Button>
                          <ExportWorkingHoursModal
                            isOpen={isExportWorkingHoursModalOpen}
                            onClose={handleExportWorkingHoursModal}
                            onExport={handleExportWorkingHours}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {isSafetyCouncil && (
                <Grid item xs={2}>
                  <Grid container justify="flex-end">
                    <Button to={routes.private.company.new} component={Link} variant="contained" color="primary">
                      Create new company
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Collapse in={moreFilter} component={Grid} item xs={12}>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  {isSafetyCouncilUserType && (
                    <Grid item xs={6}>
                      <FastField
                        label="Company Type"
                        name="companyType"
                        options={companyRolesSelectionOptions}
                        component={FormAutocomplete}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FastField
                      label="Work Categories"
                      name="workCategories"
                      options={transformedWorkCategories}
                      component={MultiselectDropdown}
                      grouped
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField
                      label="Services Performed"
                      name="services"
                      options={allServices}
                      component={MultiselectDropdown}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField label="Grade" name="grades" options={transformGrades} component={MultiselectDropdown} />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField label="State" name="states" options={transformStates} component={MultiselectDropdown} />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField label="Zip" name="zip" maskType="zip" component={FormNumberInput} />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField
                      label="PQF Status"
                      name="pqfStatuses"
                      options={statusesOptions}
                      component={MultiselectDropdown}
                    />
                  </Grid>
                  {type === userTypes.Owner && (
                    <Grid item xs={6}>
                      <FastField
                        label="Favorite"
                        name="isFavouriteContractor"
                        options={favoriteOptions}
                        component={FormAutocomplete}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FastField
                      label="Overdue"
                      name="withOverdue"
                      options={overdueOptions}
                      component={FormAutocomplete}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField label="Company User" name="companyUser" component={FormInput} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <MinMax
                  title="Average Number Employees"
                  component={FormNumberInput}
                  minValues={{
                    min: 0,
                  }}
                  names={{
                    minName: 'aemin',
                    maxName: 'aemax',
                  }}
                />
                <MinMax
                  title="Bank Line of Credit"
                  component={FormNumberInput}
                  masktype="dollarNumber"
                  names={{
                    minName: 'lcmin',
                    maxName: 'lcmax',
                  }}
                />
                <MinMax
                  title="Bonding Capacity"
                  component={FormNumberInput}
                  masktype="dollarNumber"
                  names={{
                    minName: 'bcmin',
                    maxName: 'bcmax',
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )}
    </Formik>
  );
};

export default CreateFilterForm;
