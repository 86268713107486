import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import isEqual from 'lodash.isequal';

import ParentCompanySubForm from './ParentCompanySubForm';
import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import SubsidiaryContainer from './SubsidiaryContainer';
import { enums } from '../../../utils/constants/formConstats';
import PromptNotification from '../../components/PromptComponent';

const ParentCompanyForm = ({ handleSubmit, loading, subsidiaries, companyData, success, error, allowUpdate }) => {
  const [status, setStatus] = useState({
    form: {
      values: {},
      dirty: false,
      isValid: false,
      submit: () => {},
    },
    table: {
      values: [],
      changed: false,
    },
  });

  useEffect(() => {
    if (status.form.values.isSubsidiaryExist === enums.yesno.No) {
      setStatus(prevState => ({ ...prevState, table: { values: [], changed: !isEqual([], subsidiaries) } }));
    }
  }, [status.form.values.isSubsidiaryExist, subsidiaries]);

  if (loading && !Object.keys(companyData).length) return <PageLoader />;
  const existingSubsidiaries =
    status.form.values.isSubsidiaryExist === enums.yesno.Yes ? !!status.table.values.length : true;
  const canSubmit =
    (status.form.isValid && status.form.dirty && existingSubsidiaries) ||
    (status.form.isValid && status.table.changed && existingSubsidiaries);

  const onSubmit = values => {
    const { id, phoneNumber, streetAddress, name, isParentCompanyExist, isSubsidiaryExist } = values;
    const { values: existingSubsidiaries } = status.table;
    const newValues = {
      id: companyData.id || id,
      name,
      phoneNumber: phoneNumber ? phoneNumber : null,
      streetAddress: {
        ...streetAddress,
        state: streetAddress.state ? streetAddress.state : null,
        zip: streetAddress.zip ? streetAddress.zip : null,
      },
      isParentCompanyExist: isParentCompanyExist === enums.yesno.Yes || (isParentCompanyExist === '' && null),
      isSubsidiaryExist: isSubsidiaryExist === enums.yesno.Yes || (isSubsidiaryExist === '' && null),
    };
    const newSubsidiaries =
      isSubsidiaryExist === enums.yesno.Yes
        ? existingSubsidiaries.map(subsidiary => ({
            id: subsidiary.id || '0',
            name: subsidiary.name,
          }))
        : [];

    handleSubmit(newValues, newSubsidiaries);

    setStatus({
      form: {
        ...status.form,
        dirty: false,
      },
      table: {
        ...status.table,
        changed: false,
      },
    });
  };

  const initialValues = {
    id: companyData.id,
    name: companyData.name || '',
    phoneNumber: companyData.phoneNumber || '',
    streetAddress: {
      id: companyData.streetAddress.id || 0,
      street: companyData.streetAddress.street || '',
      city: companyData.streetAddress.city || '',
      state: companyData.streetAddress.state || '',
      zip: companyData.streetAddress.zip || '',
    },
    isParentCompanyExist:
      companyData.isParentCompanyExist !== null
        ? companyData.isParentCompanyExist
          ? enums.yesno.Yes
          : enums.yesno.No
        : '',
    isSubsidiaryExist:
      companyData.isSubsidiaryExist !== null ? (companyData.isSubsidiaryExist ? enums.yesno.Yes : enums.yesno.No) : '',
  };

  const { isSubsidiaryExist } = status.form.values;
  return (
    <>
      <PromptNotification formStatus={status.form.dirty || status.table.changed} />
      <FormBody loading={loading} disabled={!allowUpdate}>
        <Grid item xs={12}>
          <Grid container alignItems="flex-start">
            <Grid item xs={5}>
              <ParentCompanySubForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                formUpdate={(values, dirty, isValid, handleSubmit) =>
                  setStatus({
                    ...status,
                    form: {
                      values,
                      isValid,
                      dirty,
                      submit: handleSubmit,
                    },
                  })
                }
              />
            </Grid>
            {isSubsidiaryExist === enums.yesno.Yes && (
              <SubsidiaryContainer
                tableData={subsidiaries}
                setTableData={values =>
                  setStatus({
                    ...status,
                    table: {
                      values,
                      changed: !isEqual(values, subsidiaries),
                    },
                  })
                }
                loading={loading}
                allowUpdate={allowUpdate}
                initialTableValues={{ name: '' }}
              />
            )}
          </Grid>
        </Grid>
        {allowUpdate && (
          <LoadingButton
            fixed
            disabled={!canSubmit}
            text="save"
            loading={loading}
            success={success}
            error={error}
            onClick={() => status.form.submit()}
          />
        )}
      </FormBody>
    </>
  );
};

export default ParentCompanyForm;
