import React, { useEffect } from 'react';
import { FastField, Formik } from 'formik';
import { Grid, Typography, Collapse } from '@material-ui/core';

import { parentCompanySchema } from '../../../../utils/validate/shemas';
import { yesnoOptions, enums } from '../../../../utils/constants/formConstats';
import FormRadio from '../../../components/FormRadio';
import FormInput from '../../../components/FormInput';
import UsaStates from '../../../../utils/constants/usaStates';
import useStyles from './styles';
import FormNumberInput from '../../../components/FormNumberInput';
import FormAutocomplete from '../../../components/FormAutocomplete';

const WatchEffect = ({ dirty, isValid, values, formUpdate, handleSubmit }) => {
  useEffect(() => {
    formUpdate(values, dirty, isValid, () => handleSubmit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, values, isValid]);
  return null;
};

const FormRadioControl = ({ valuesAfterHide, field, form, ...props }) => {
  const newField = {
    ...field,
    onChange: e => {
      if (e.target.value === enums.yesno.No) {
        form.setValues({ ...form.values, ...valuesAfterHide, [field.name]: e.target.value }, true);
      } else {
        field.onChange(e);
      }
    },
  };
  return <FormRadio {...props} form={form} field={newField} />;
};

const ParentCompanySubForm = ({ initialValues, formUpdate, onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik initialValues={initialValues} validationSchema={parentCompanySchema} onSubmit={onSubmit}>
      {props => {
        const { isParentCompanyExist } = props.values;
        return (
          <>
            <WatchEffect
              formUpdate={formUpdate}
              dirty={props.dirty}
              isValid={props.isValid}
              values={props.values}
              handleSubmit={props.handleSubmit}
            />
            <Grid container direction="row" alignItems="center" className={classes.formRadioContainer}>
              <Typography variant="subtitle2" gutterBottom>
                Do you have a Parent Company?
              </Typography>
              <FastField
                name="isParentCompanyExist"
                component={FormRadioControl}
                valuesAfterHide={{
                  name: '',
                  phoneNumber: '',
                  streetAddress: {
                    id: props.values.streetAddress.id,
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                  },
                }}
                options={yesnoOptions}
              />
            </Grid>
            <Collapse in={isParentCompanyExist === enums.yesno.Yes}>
              {isParentCompanyExist === enums.yesno.Yes && (
                <Grid container alignItems="flex-start">
                  <Grid item xs={10} className={classes.itemContainer}>
                    <FastField
                      className={classes.inputRow}
                      label="Parent Company Name"
                      name="name"
                      component={FormInput}
                    />
                    <FastField
                      className={classes.inputRow}
                      label="Phone Number"
                      type="tel"
                      name="phoneNumber"
                      component={FormInput}
                    />
                  </Grid>
                  <Grid item xs={10} className={classes.itemContainer}>
                    <Typography variant="h5" gutterBottom className={classes.title}>
                      Street Address
                    </Typography>
                    <FastField
                      className={classes.inputRow}
                      label="Address"
                      name="streetAddress.street"
                      component={FormInput}
                    />
                    <FastField
                      className={classes.inputRow}
                      label="City"
                      name="streetAddress.city"
                      component={FormInput}
                    />
                    <FastField
                      className={classes.inputRow}
                      label="State"
                      name="streetAddress.state"
                      id="state"
                      options={UsaStates}
                      component={FormAutocomplete}
                    />
                    <FastField
                      className={classes.inputRow}
                      label="Zip"
                      name="streetAddress.zip"
                      maskType="zip"
                      component={FormNumberInput}
                    />
                  </Grid>
                </Grid>
              )}
            </Collapse>
            <Grid container direction="row" alignItems="center" className={classes.formRadioContainer}>
              <Typography variant="subtitle2" gutterBottom>
                Do you have a Subsidiary Company?
              </Typography>
              <FastField name="isSubsidiaryExist" component={FormRadio} options={yesnoOptions} />
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default ParentCompanySubForm;
