import { all, put, call, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* create({ payload: { companyId, values, subsidiaries, sectionKey } }) {
  try {
    const [companyData, subsidiariesData] = yield all([
      call(dataProvider.createOne, `companies/${companyId}/parent-company`, values),
      subsidiaries.length
        ? call(dataProvider.createList, `companies/${companyId}/Subsidiaries`, subsidiaries)
        : { data: [] },
    ]);

    yield put({
      type: currentCompany.parentCompany.create.end,
      payload: { companyData: companyData.data, subsidiaries: subsidiariesData.data },
    });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.parentCompany.create.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* createParentCompanySaga() {
  yield takeLatest(currentCompany.parentCompany.create.start, create);
}
