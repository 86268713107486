import React from 'react';
import { FastField, Formik } from 'formik';
import { Divider, Grid } from '@material-ui/core';

import { officersSchema } from '../../../utils/validate/shemas';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import PromptNotification from '../../components/PromptComponent';
import FileUploaderInput from '../../components/FileUploaderInput';
import {
  changeNullToEmptyString,
  formatDateForInitialValues,
  getValueForRadioButton,
} from '../../../utils/containersHelpers';
import { enums } from '../../../utils/constants/formConstats';
import useStyles from './styles';
import OfficerManagerSubForm from './OfficerManagerSubForm';
import OfficersSubForm from './OfficersSubForm';
import OfficersSubFormWrapper from './OfficersSubFormWrapper';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const getValue = value => {
  if (value === '') {
    return null;
  }
  return value === enums.yesno.Yes;
};

const CreateOfficersForm = ({
  handleSubmit,
  dbValues,
  loading,
  success,
  error,
  requestInProgress,
  allowUpdate,
  companyId,
}) => {
  const classes = useStyles();
  const { isBasicContractor } = useCompanyInfo(companyId);

  if (loading || Object.keys(dbValues).length === 0) {
    return <PageLoader />;
  }

  const onSubmit = values => {
    const { president, vicePresident, treasurer } = values;
    const newValues = {
      ...values,
      president: {
        ...president,
        isPositionExist: getValue(president.isPositionExist),
      },
      vicePresident: {
        ...vicePresident,
        isPositionExist: getValue(vicePresident.isPositionExist),
      },
      treasurer: {
        ...treasurer,
        isPositionExist: getValue(treasurer.isPositionExist),
      },
    };

    handleSubmit(newValues);
  };

  const initialValues = formatDateForInitialValues(changeNullToEmptyString(dbValues));

  const { president, vicePresident, treasurer } = initialValues;

  president.isPositionExist = getValueForRadioButton(president.isPositionExist);
  vicePresident.isPositionExist = getValueForRadioButton(vicePresident.isPositionExist);
  treasurer.isPositionExist = getValueForRadioButton(treasurer.isPositionExist);

  return (
    <Formik initialValues={initialValues} validationSchema={officersSchema} onSubmit={onSubmit} enableReinitialize>
      {props => {
        return (
          <>
            <PromptNotification formStatus={props.dirty} />
            <FormBody loading={loading} disabled={!allowUpdate}>
              <Grid item xs={12}>
                <Grid container>
                  <OfficersSubForm
                    position="CEO/Owner"
                    names={{
                      title: 'ceo.name',
                      email: 'ceo.emailAddress',
                      date: 'ceo.dateHired',
                    }}
                    {...props}
                  />
                  {isBasicContractor && (
                    <Grid item xs={6} className={classes.upload}>
                      <FastField
                        name="orgchart"
                        component={FileUploaderInput}
                        label="Upload Organization Chart"
                        companyId={companyId}
                      />
                    </Grid>
                  )}
                </Grid>
                {!isBasicContractor && (
                  <>
                    <Divider light className={classes.devider} />
                    <OfficersSubFormWrapper
                      questionName="president.isPositionExist"
                      isOfficerExists={props.values.president.isPositionExist}
                      position="President"
                      names={{
                        title: 'president.name',
                        email: 'president.emailAddress',
                        date: 'president.dateHired',
                      }}
                      {...props}
                    />

                    <Divider light className={classes.devider} />
                    <OfficersSubFormWrapper
                      questionName="vicePresident.isPositionExist"
                      isOfficerExists={props.values.vicePresident.isPositionExist}
                      position="Vice President"
                      formNames={{
                        title: 'vicePresident.name',
                        email: 'vicePresident.emailAddress',
                        date: 'vicePresident.dateHired',
                      }}
                      {...props}
                    />
                    <Divider light className={classes.devider} />
                    <OfficersSubFormWrapper
                      questionName="treasurer.isPositionExist"
                      isOfficerExists={props.values.treasurer.isPositionExist}
                      position="Treasurer"
                      formNames={{
                        title: 'treasurer.name',
                        email: 'treasurer.emailAddress',
                        date: 'treasurer.dateHired',
                      }}
                      {...props}
                    />
                  </>
                )}
                <Divider light className={classes.devider} />
                <Grid container direction="row" justify="flex-start">
                  <OfficerManagerSubForm
                    position="Bid Manager"
                    names={{
                      firstName: 'bidManager.firstName',
                      lastName: 'bidManager.lastName',
                      title: 'bidManager.title',
                      email: 'bidManager.email',
                      fax: 'bidManager.fax',
                      phone: 'bidManager.phone',
                    }}
                    {...props}
                  />
                  {!isBasicContractor && (
                    <Grid item xs={6} className={classes.upload}>
                      <FastField
                        name="orgchart"
                        component={FileUploaderInput}
                        label="Upload Organization Chart"
                        companyId={companyId}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {allowUpdate && (
                <LoadingButton
                  fixed
                  disabled={!props.dirty || !props.isValid}
                  success={success}
                  error={error}
                  loading={requestInProgress}
                  onClick={props.handleSubmit}
                  text="save"
                />
              )}
            </FormBody>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateOfficersForm;
