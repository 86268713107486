import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { currentCompany, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { company as companySchema } from '../../../schema';
import { updateFiles } from '../../file/updateFiles';
import { uploadSelector } from '../../../utils/pageHelpers';

export function* update({ payload: { id, values, sectionKey } }) {
  try {
    const { uploadKeys, ...dirtyValues } = values;
    const formValues = Object.keys(dirtyValues).reduce(
      (acc, item) => (uploadKeys.includes(item) ? acc : { ...acc, [item]: dirtyValues[item] }),
      {},
    );

    // update files
    if (uploadKeys.length) {
      yield all(
        uploadKeys.map(
          key =>
            (values[key].createList.length || values[key].deleteList.length) &&
            call(updateFiles, {
              companyId: id,
              deleteList: values[key].deleteList,
              createList: values[key].createList,
              sectionKey: key,
            }),
        ),
      );
    }

    const fileObj = yield select(store => uploadSelector(store, uploadKeys));

    // update company info
    const newValues = {
      ...formValues,
      logoLink: '',
      id,
      isoCertified: values.isoCertified ? 1 : 0,
    };

    let formData = new FormData();
    formData.append('model', JSON.stringify(newValues));
    formData.append('companyLogo', values.logoLink);
    const { data } = yield call(dataProvider.updateOneFormData, `Companies/${id}`, formData);

    const normalizeData = normalize([{ ...data, uploadKeys, ...fileObj }], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });
    yield put({ type: currentCompany.info.update.end, payload: { state: normalizeData.result } });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: id, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.info.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateCompanyInfoSaga() {
  yield takeLatest(currentCompany.info.update.start, update);
}
