import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Grid, Typography } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import NotesIcon from '@material-ui/icons/Notes';

import routes from '../../../routes/path';
import Ellipsis from '../../../components/Ellipsis';
import AdminPopUp from '../../../components/AdminPopUp';
import IconWithPopUp from '../../../components/IconWithPopUp';
import { userTypes } from '../../../../utils/constants';
import { formatPath } from '../../../../utils/textFormaters';
import FillingCell from './FillingCell';
import CellStatus from './CellStatus';
import FavoriteCell from './FavoriteCell';
import OverdueCell from './OverdueCell';
import ExpDate from './ExpDate';
import { createEnum } from '../../../../utils/actionTypesHelpers';
import { optionTypes } from '../../../../utils/constants/companyRoleTypes';

export const columnEnum = createEnum({
  companyName: {
    key: 'companyName',
    title: 'Company Name',
  },
  favorite: {
    key: 'favorite',
    title: 'Favorite',
  },
  companyType: {
    key: 'companyType',
    title: 'Company Type',
  },
  city: {
    key: 'city',
    title: 'City',
  },
  state: {
    key: 'state',
    title: 'State',
  },
  zip: {
    key: 'zip',
    title: 'Zip Code',
  },
  webSite: {
    key: 'webSite',
    title: 'Website',
  },
  phone: {
    key: 'phone',
    title: 'Phone Number',
  },
  grade: {
    key: 'grade',
    title: 'Grade',
  },
  ownerGrade: {
    key: 'ownerGrade',
    title: 'Own grade',
  },
  valid: {
    key: 'valid',
    title: 'Filing',
  },
  overdue: {
    key: 'overdue',
    title: 'Overdue',
  },
  status: {
    key: 'status',
    title: 'PQF status',
  },
  psmCompleteDate: {
    key: 'psmCompleteDate',
    title: 'Last PSM',
  },
  expDate: {
    key: 'expDate',
    title: 'Expiration',
  },
  actions: {
    key: 'actions',
    title: 'Actions',
  },
});

export const disabled = [
  columnEnum.companyName.key,
  columnEnum.actions.key,
  columnEnum.valid.key,
  columnEnum.status.key,
];

export const getColumns = (
  handleDelete,
  handleAddToFavorite,
  loadingListAddToFavorite,
  type,
  order,
  visibleColumns,
  setOpenModal,
  classes,
) => {
  const renderControlPanel = ({ id, adminNotes, typeName, level }) => (
    <Grid container justify="space-around" item xs={12}>
      <AdminPopUp title="Admin Notes" tooltipTitle="Notes" note={adminNotes}>
        <NotesIcon />
      </AdminPopUp>
      <div className={classes.settings}>
        <IconWithPopUp
          handler={() => handleDelete(id)}
          title="Are you sure you want to delete this company?"
          tooltipTitle="Delete"
        >
          <DeleteIcon color="error" />
        </IconWithPopUp>
      </div>
      <IconButton to={formatPath(routes.private.company.settingsInfo, { id })} component={Link} size="small">
        <Settings />
      </IconButton>
    </Grid>
  );

  const columns = {
    [columnEnum.companyName.key]: {
      title: columnEnum.companyName.title,
      render: ({ companyName, typeName, id }) => (
        <Ellipsis
          title={companyName}
          showLink={!(typeName === userTypes.Owner)}
          to={formatPath(routes.private.company.id, { id })}
          companyName
        />
      ),
      sortField: 'companyName',
      defaultSort: order,
      // width: '12%',
    },
    [columnEnum.favorite.key]: {
      title: columnEnum.favorite.title,
      render: ({ id, isFavourite }) => (
        <FavoriteCell
          id={id}
          isFavourite={isFavourite}
          handleAddToFavorite={handleAddToFavorite}
          loadingListAddToFavorite={loadingListAddToFavorite}
        />
      ),
      sorting: false,
      // width: '4%',
    },
    [columnEnum.companyType.key]: {
      title: columnEnum.companyType.title,
      render: ({ typeName, level }) => (
        <Typography variant="body1">
          {typeName === userTypes.Contractor ? optionTypes[level] : optionTypes[typeName]}
        </Typography>
      ),
      sortField: 'companyType',
      defaultSort: order,
      // width: '8%',
    },
    [columnEnum.city.key]: {
      title: columnEnum.city.title,
      render: ({ streetAddress: { city } }) => <Ellipsis title={city || ''} />,
      sortField: 'city',
      // width: '8%',
    },
    [columnEnum.state.key]: {
      title: columnEnum.state.title,
      render: ({ streetAddress: { state } }) => <Ellipsis title={state} />,
      sortField: 'state',
      // width: '8%',
    },
    [columnEnum.zip.key]: {
      title: columnEnum.zip.title,
      field: 'streetAddress.zip',
      sorting: false,
      // width: '8%',
    },
    [columnEnum.webSite.key]: {
      title: columnEnum.webSite.title,
      render: ({ webSite }) => <Ellipsis title={webSite} showLink={true} href={webSite} target="_blank" />,
      sorting: false,
      // width: '12%',
    },
    [columnEnum.phone.key]: {
      title: columnEnum.phone.title,
      render: ({ phoneNumber }) => phoneNumber,
      sorting: false,
      // width: '8%',
    },
    [columnEnum.grade.key]: {
      title: columnEnum.grade.title,
      render: ({ grade }) => <Ellipsis title={grade} />,
      sorting: false,
      // width: '4%',
    },
    [columnEnum.ownerGrade.key]: {
      title: columnEnum.ownerGrade.title,
      render: ({ ownerGrade }) => <Ellipsis title={ownerGrade || 'TBD'} />,
      sorting: false,
      // width: '4%',
    },
    [columnEnum.valid.key]: {
      title: columnEnum.valid.title,
      render: ({ isPqfValid, typeName }) => typeName === userTypes.Contractor && <FillingCell valid={isPqfValid} />,
      sortField: 'filling',
      // width: '4%',
    },
    [columnEnum.overdue.key]: {
      title: columnEnum.overdue.title,
      render: ({ withOverdue }) => <OverdueCell overdue={withOverdue} />,
      sorting: false,
      // width: '4%',
    },
    [columnEnum.status.key]: {
      title: columnEnum.status.title,
      render: ({ id, currentPqfDocumentId, typeName, status, rejectReason, completedOn }) =>
        typeName !== userTypes.Owner && (
          <CellStatus
            id={id}
            status={status}
            currentPqfDocumentId={currentPqfDocumentId}
            rejectReason={rejectReason}
            completedOn={completedOn}
            showReviewForOwner={type !== userTypes.Owner}
          />
        ),
      sortField: 'pqf',
      // width: '6%',
    },
    [columnEnum.psmCompleteDate.key]: {
      title: columnEnum.psmCompleteDate.title,
      render: ({ psmCompleteDate, typeName, level }) => (
        <ExpDate
          key={1}
          warningTime={2}
          expDate={psmCompleteDate}
          company={typeName === userTypes.Contractor ? optionTypes[level] : optionTypes[typeName]}
          isPSM
        />
      ),
      // width: '10%',
      sortField: 'psmCompleteDate',
      defaultSort: order,
    },
    [columnEnum.expDate.key]: {
      title: type === userTypes.SafetyCouncil && columnEnum.expDate.title,
      render:
        type === userTypes.SafetyCouncil
          ? ({ subscriptionExpirationDate }) => <ExpDate key={2} warningTime={1} expDate={subscriptionExpirationDate} />
          : () => {},
      // width: '10%',
      sortField: type === userTypes.SafetyCouncil ? 'subscriptionExpirationDate' : false,
      defaultSort: order,
    },
    [columnEnum.actions.key]: {
      title: type === userTypes.SafetyCouncil && (
        <Grid item xs={12}>
          <Grid container justify="center">
            <IconButton onClick={() => setOpenModal(true)} size="small">
              <MenuIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      ),
      sorting: false,
      render: type === userTypes.SafetyCouncil ? renderControlPanel : () => {},
      width: 'fit-content',
    },
  };

  return visibleColumns.map(columnName => columns[columnName]);
};
