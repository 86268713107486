import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import get from 'lodash.get';

import phoneInput from './phoneInput';
import useStyles from './styles';

const integerRegex = /^((?!00)[0-9]*)$/;
const floatRegex = /^((?!00)[0-9]+([.][0-9]*)?)$/;
const decimalsRegex = /^((?!00)[0-9]+([.][0-9]{0,2})?)$/;
const textOnlyRegex = /^[A-Za-z\s]+$/;
const float = 'float';
const decimal = 'decimal';
const integer = 'integer';

const getRegex = value => {
  if (value === float) return floatRegex;
  if (value === decimal) return decimalsRegex;
  return integerRegex;
};

const FormInput = ({
  size = 'small',
  handleChange,
  isIntegerInput,
  isFloatInput,
  type = 'text',
  min,
  maxLength,
  max,
  field,
  form: { errors, touched, setFieldValue },
  number, // 'float' || 'integer' || decimal,
  textOnly,
  tabIndex,
  ...props
}) => {
  const classes = useStyles();

  const { onChange, ...fieldProps } = field;
  const itemError = get(errors, field.name);
  const itemTouched = get(touched, field.name);

  const isValidNumber = value => {
    let isValidInput = true;
    if (number === integer || number === float || number === decimal) {
      const minNumber = min || 0;
      const regex = getRegex(number);
      const rawValue = value === '' ? minNumber : value;
      const parsedValue = parseFloat(rawValue);
      if ((min && parsedValue < min) || (max && parsedValue > max) || !regex.test(rawValue)) isValidInput = false;
    }
    return isValidInput;
  };

  return (
    <TextField
      {...props}
      {...fieldProps}
      fullWidth
      variant="outlined"
      size={size}
      type={type}
      min={min}
      max={max}
      onChange={e => {
        handleChange && handleChange(e, fieldProps);
        textOnly
          ? (textOnlyRegex.test(e.target.value.trim()) || e.target.value === '') && onChange(e)
          : isValidNumber(e.target?.value) && onChange(e);
      }}
      error={!!itemError && !!itemTouched}
      helperText={!!itemError && itemTouched && itemError}
      inputProps={{ maxLength, tabIndex }}
      InputProps={
        (type === 'tel' && { inputComponent: phoneInput, inputProps: { setFieldValue } }) ||
        (type === 'search' && {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }) ||
        (type === 'percentage' && {
          endAdornment: (
            <InputAdornment position="end">
              <Typography className={classes.percentage}>%</Typography>
            </InputAdornment>
          ),
        }) ||
        undefined
      }
      InputLabelProps={
        (type === 'tel' && { shrink: true }) || undefined
      }
    />
  );
};

export default FormInput;
